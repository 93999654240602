import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DepositConfirmPopup from "../withdraw/withdarw-popup";
import coin from "../../../assets/images/wallet/depositcoin.svg";
import app from "../../../assets/images/wallet/cashapp.svg";
import chimeImage from "../../../assets/images/wallet/chimeImage.svg";
import venmo from "../../../assets/images/wallet/Venmo.svg";
import paypal from "../../../assets/images/wallet/paypal.svg";
import card1 from "../../../assets/images/wallet/visa.svg";
import card2 from "../../../assets/images/wallet/master.svg";
import card3 from "../../../assets/images/wallet/maestro.svg";
import bit from "../../../assets/images/wallet/bitcoin.svg";
import "./depositpopup.css";
import { errorMsgTimeout } from "../../../utils";
// import {convertCurrency} from '../../../utils/index'

const DepositPopup = ({ handleClose, deposit, setDeposit }) => {
  const [showDeposit, setShowDeposit] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [steps, setSteps] = useState(0);
  const initialDeposit = {
    coin: "",
    amountInUsd: "",
    cryptoPrice: "",
    payment: "cashApp",
    status: "idle",
  };
  const paymentData = [
    {
      name: "cashApp",
      title: "CashApp",
      logo: app,
    },
    {
      name: "btc",
      title: "Crypto",
      logo: bit,
    },
    {
      name: "chime",
      title: "Chime",
      logo: chimeImage,
    },
    {
      name: "venmo",
      title: "Venmo",
      logo: venmo,
    },
    {
      name: "paypal",
      title: "Paypal",
      logo: paypal,
    },
  ];
  const [depositData, setDepositData] = useState(initialDeposit);
  const closePopup = () => {
    setDepositData(initialDeposit);
    handleClose();
  };
  const handleShowDeposit = async () => {
    if (parseFloat(depositData.coin) > 0) {
      setDeposit(false);
      setShowDeposit(!showDeposit);
      setSteps(0);
    } else {
      setErrorMsg("Please enter amount of Coins.");
      setTimeout(() => {
        setErrorMsg("");
      }, errorMsgTimeout);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    const copyDepositData = { ...depositData };
    copyDepositData[name] = value;
    if (name === "coin") {
      copyDepositData.amountInUsd = value * 1;
      copyDepositData.cryptoPrice = value * 0.000014;
    }
    setDepositData(copyDepositData);
  };

  return (
    <div className="deposit-popup-wrapper">
      <Modal
        size="lg"
        className="coin-popup"
        show={deposit}
        onHide={() => closePopup()}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="coin-popup-content">
            <h1>Deposit money</h1>

            <div className="deposit-popup-content">
              <div className="deposit-popup-input">
                <input
                  type="number"
                  value={depositData.coin}
                  name="coin"
                  placeholder="Amount of coins you want to get"
                  className="form-control"
                  onChange={handleOnChange}
                />
                <div className="deposit-popup-money">
                  <img src={coin} alt="" />
                  <span>{depositData.coin || 0}</span>
                </div>
              </div>
            </div>
            {errorMsg && (
              <>
                <h6 className="text-danger mt-2 pb-1">{errorMsg}</h6>
              </>
            )}
            <div className="deposit-popup-transfer">
              <h4>Please choose the type of payment you want to use</h4>
              <div className="">
                <div className="deposit-popup-transfer-wrapper deposit-popup-fix">
                  {/* <label htmlFor="cashapp1">
                      <div
                        className={`deposit-popup-transfer-box ${
                          depositData.payment === "cashApp" ? "active" : ""
                        }`}
                      >
                        <div className="deposit-transfer-box-top">
                          <div className="deposit-cash-app">
                            <input
                              type="radio"
                              id="cashapp1"
                              name="payment"
                              value="cashApp"
                              checked={
                                depositData.payment === "cashApp" ? true : false
                              }
                              onChange={handleOnChange}
                            />
                             {" "}
                            <label htmlFor="cashapp1">
                              <img src={app} alt="" />
                              CashApp
                            </label>
                          </div>
                          <div className="deposit-coin">
                            <span>1 COIN = 1 USD</span>
                          </div>
                          <div className="deposit-card">
                            <img src={card1} alt="" />
                            <img src={card2} alt="" />
                            <img src={card3} alt="" />
                          </div>
                        </div>
                        <div className="deposit-transfer-box-bottom">
                          <p>Deposit money with your Cash App</p>
                          <span>${depositData?.amountInUsd || 0}</span>
                        </div>
                      </div>
                    </label>
                    <label htmlFor="cashapp2">
                      <div
                        className={`deposit-popup-transfer-box ${
                          depositData.payment === "btc" ? "active" : ""
                        }`}
                      >
                        <div className="deposit-transfer-box-top">
                          <div className="deposit-cash-app">
                            <input
                              type="radio"
                              id="cashapp2"
                              name="payment"
                              value="btc"
                              checked={
                                depositData.payment === "btc" ? true : false
                              }
                              onChange={handleOnChange}
                            />
                              <label htmlFor="cashapp2">Crypto</label>
                          </div>
                          <div className="deposit-coin">
                            <span>1 COIN = 0.000047 BTC</span>
                          </div>
                          <div className="deposit-card">
                            <img src={bit} alt="" />
                          </div>
                        </div>
                        <div className="deposit-transfer-box-bottom">
                          <p>Deposit money with your Crypto Wallet</p>
                          <span>BTC {depositData?.cryptoPrice}</span>
                        </div>
                      </div>
                    </label>
                    <label htmlFor="chime">
                      <div
                        className={`deposit-popup-transfer-box ${
                          depositData.payment === "chimePayment" ? "active" : ""
                        }`}
                      >
                        <div className="deposit-transfer-box-top">
                          <div className="deposit-cash-app">
                            <input
                              type="radio"
                              id="chime"
                              name="payment"
                              value="chimePayment"
                              checked={
                                depositData.payment === "chimePayment"
                                  ? true
                                  : false
                              }
                              onChange={handleOnChange}
                            />
                             {" "}
                            <label htmlFor="chime">
                              <img src={chimeImage} alt="" />
                              Chime
                            </label>
                          </div>
                          <div className="deposit-coin">
                            <span>1 COIN = 1 USD</span>
                          </div>
                          <div className="deposit-card">
                            <img src={card1} alt="" />
                            <img src={card2} alt="" />
                            <img src={card3} alt="" />
                          </div>
                        </div>
                        <div className="deposit-transfer-box-bottom">
                          <p>Deposit money with Chime</p>
                          <span>${depositData?.amountInUsd || 0}</span>
                        </div>
                      </div>
                    </label>
                    <label htmlFor="venmo">
                      <div
                        className={`deposit-popup-transfer-box ${
                          depositData.payment === "venmoPayment" ? "active" : ""
                        }`}
                      >
                        <div className="deposit-transfer-box-top">
                          <div className="deposit-cash-app">
                            <input
                              type="radio"
                              id="venmo"
                              name="payment"
                              value="venmoPayment"
                              checked={
                                depositData.payment === "venmoPayment"
                                  ? true
                                  : false
                              }
                              onChange={handleOnChange}
                            />
                             {" "}
                            <label htmlFor="venmo">
                              <img src={venmo} alt="" />
                              Venmo
                            </label>
                          </div>
                          <div className="deposit-coin">
                            <span>1 COIN = 1 USD</span>
                          </div>
                          <div className="deposit-card">
                            <img src={card1} alt="" />
                            <img src={card2} alt="" />
                            <img src={card3} alt="" />
                          </div>
                        </div>
                        <div className="deposit-transfer-box-bottom">
                          <p>Deposit money with Venmo</p>
                          <span>${depositData?.amountInUsd || 0}</span>
                        </div>
                      </div>
                    </label>
                    <label htmlFor="paypal">
                      <div
                        className={`deposit-popup-transfer-box ${
                          depositData.payment === "paypalPayment"
                            ? "active"
                            : ""
                        }`}
                      >
                        <div className="deposit-transfer-box-top">
                          <div className="deposit-cash-app">
                            <input
                              type="radio"
                              id="paypal"
                              name="payment"
                              value="paypalPayment"
                              checked={
                                depositData.payment === "paypalPayment"
                                  ? true
                                  : false
                              }
                              onChange={handleOnChange}
                            />
                             {" "}
                            <label htmlFor="paypal">
                              <img src={paypal} alt="" />
                              Paypal
                            </label>
                          </div>
                          <div className="deposit-coin">
                            <span>1 COIN = 1 USD</span>
                          </div>
                          <div className="deposit-card">
                            <img src={card1} alt="" />
                            <img src={card2} alt="" />
                            <img src={card3} alt="" />
                          </div>
                        </div>
                        <div className="deposit-transfer-box-bottom">
                          <p>Deposit money with Paypal</p>
                          <span>${depositData?.amountInUsd || 0}</span>
                        </div>
                      </div>
                    </label> */}

                  {paymentData.map((payment, index) => {
                    return (
                      <>
                        {payment.name !== "btc" ? (
                          <label htmlFor="paypal">
                            <div
                              className={`deposit-popup-transfer-box ${
                                depositData.payment === payment.name
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                handleOnChange({
                                  target: {
                                    name: "payment",
                                    value: payment.name,
                                  },
                                })
                              }
                            >
                              <div className="deposit-transfer-box-top">
                                <div className="deposit-cash-app">
                                  <input
                                    type="radio"
                                    id={`payment-${index}`}
                                    name="payment"
                                    value={payment.name}
                                    checked={
                                      depositData.payment === payment.name
                                        ? true
                                        : false
                                    }
                                    onChange={handleOnChange}
                                  />
                                   {" "}
                                  <label htmlFor={`payment-${index}`}>
                                    <img src={payment.logo} alt="" />
                                    {payment.title}
                                  </label>
                                </div>
                                <div className="deposit-coin">
                                  <span>1 COIN = 1 USD</span>
                                </div>
                                <div className="deposit-card">
                                  <img src={card1} alt="" />
                                  <img src={card2} alt="" />
                                  <img src={card3} alt="" />
                                </div>
                              </div>
                              <div className="deposit-transfer-box-bottom">
                                <p>Deposit money with {payment.name}</p>
                                <span>${depositData?.amountInUsd || 0}</span>
                              </div>
                            </div>
                          </label>
                        ) : (
                          <label htmlFor="cashapp2">
                            <div
                              className={`deposit-popup-transfer-box ${
                                depositData.payment === payment.name
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <div className="deposit-transfer-box-top">
                                <div className="deposit-cash-app">
                                  <input
                                    type="radio"
                                    id={`payment-${index}`}
                                    name="payment"
                                    value="btc"
                                    checked={
                                      depositData.payment === payment.name
                                        ? true
                                        : false
                                    }
                                    onChange={handleOnChange}
                                  />
                                   {" "}
                                  <label htmlFor={`payment-${index}`}>
                                    {payment.title}
                                  </label>
                                </div>
                                <div className="deposit-coin">
                                  <span>1 COIN = 0.000047 BTC</span>
                                </div>
                                <div className="deposit-card">
                                  <img src={payment.logo} alt="" />
                                </div>
                              </div>
                              <div className="deposit-transfer-box-bottom">
                                <p>Deposit money with your Crypto Wallet</p>
                                <span>BTC {depositData?.cryptoPrice}</span>
                              </div>
                            </div>
                          </label>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="game-box-btn">
              <div className="transparent-btn">
                <Button onClick={closePopup}>cancel</Button>
              </div>
              <div className="red-btn">
                <Button onClick={handleShowDeposit}>Deposit</Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <DepositConfirmPopup
        showDeposit={showDeposit}
        handleShowDeposit={handleShowDeposit}
        depositData={depositData}
        setDepositData={setDepositData}
        steps={steps}
        setSteps={setSteps}
      />
    </div>
  );
};

export default DepositPopup;
