import { useState, useEffect, useContext } from "react";
import SearchBoxComponent from "../search/searchBoxComponent";
import { Button, Modal, FormControl, Spinner } from "react-bootstrap";
import "./game-recharge.css";
import game from "../../../assets/images/wallet/game.svg";
import remote from "../../../assets/images/wallet/gameremote.svg";
import ClientLayout from "../clientlayout/clientlayout";
import PageHeader from "../pageheader/pageheader";
import thumbiocn from "../../../assets/images/offer/thumb-icon.svg";
import coinicon from "../../../assets/images/header/coin-icon.svg";
import { createRecharge, getGames } from "../../../utils/Api";
import UserContext from "../../../context/UserContext";
import TableLoader from "../../table_loader/tableloader";
import NoData from "../../noData";

const GameRecharge = () => {
  const [recharge, setRecharge] = useState(false);
  const [rechargeSuccess, setRechargeSuccess] = useState(false);
  const [games, setGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [rechargeInput, setRechargeInput] = useState("");
  const [loading, setLoading] = useState(false);
  const handleRecharge = (data) => {
    setSelectedGame(data);
    setRecharge(!recharge);
  };
  const handleRechargeSuccess = (from) => {
    if (from === "rechargeSuccess") {
      setRechargeInput("");
    }
    setRecharge(false);
    setRechargeSuccess(!rechargeSuccess);
  };

  const handleSearch = async () => {};
  useEffect(() => {
    const getAllGames = async () => {
      setLoading(true);
      const res = await getGames({ sort: "-createdAt", limit: 10 });
      setLoading(false);
      if (res.status === 200) {
        setGames(res.data.game.results);
      }
    };
    getAllGames();
  }, []);

  return (
    <ClientLayout>
      <div className="game-recahrge-page">
        <PageHeader pageTitle="Game Recharge" btnTitle="Deposit money" />
        <div className="container">
          <div className="admin-content-wrapper">
            <div className="recharged-game">
              <SearchBoxComponent handleSearch={handleSearch} />
              {!loading ? (
                <div className="recharged-game-content">
                  <div className="wallet-user-recent-game-grid">
                    {games.length > 0 ? (
                      <>
                        {games.map(({ id, name, playCount, logo }, index) => (
                          <div className="recent-game_box" key={id}>
                            <img src={logo || game} alt="" />
                            <div className="recent-game-detail">
                              <div className="recent-game-detail-bottom">
                                <div className="recent-game-detail-left">
                                  <p>{name || ""}</p>
                                  <div className="recent-game-detail-img">
                                    <img src={remote} alt="" />
                                    <span>{playCount || 0}</span>
                                  </div>
                                </div>
                                <div className="recent-game-detail-right">
                                  <Button
                                    className="red-btn"
                                    onClick={() =>
                                      handleRecharge({
                                        name,
                                        playCount,
                                        logo,
                                        id,
                                      })
                                    }
                                  >
                                    Recharge
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <NoData heading="No game Recharge Data" />
                    )}
                  </div>
                </div>
              ) : (
                <TableLoader />
              )}
            </div>
          </div>
        </div>
        <Recharge
          recharge={recharge}
          handleRecharge={handleRecharge}
          handleRechargeSuccess={handleRechargeSuccess}
          selectedGame={selectedGame}
          rechargeInput={rechargeInput}
          setRechargeInput={setRechargeInput}
        />
        <RechargeSuccess
          handleRechargeSuccess={handleRechargeSuccess}
          selectedGame={selectedGame}
          rechargeSuccess={rechargeSuccess}
          rechargeInput={rechargeInput}
          setRechargeInput={setRechargeInput}
        />
      </div>
    </ClientLayout>
  );
};

export default GameRecharge;

const Recharge = ({
  recharge,
  handleRecharge,
  handleRechargeSuccess,
  selectedGame,
  rechargeInput,
  setRechargeInput,
}) => {
  const { user, setUser } = useContext(UserContext);
  const { name, playCount, logo, id } = selectedGame || {};
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);
  const handleRechargeClick = async () => {
    const formData = new FormData();

    const rechargevalue = parseInt(rechargeInput);
    if (rechargevalue > user.coin || 0) {
      setError("Not enough coins.");
    } else if (!rechargevalue) {
      setError("Please enter the amount of coins");
    } else {
      // const payload = {
      //   coin: rechargevalue,
      //   payment: "coin",
      //   gameId: id,
      // };
      setIsRequestSubmitted(true);
      formData.append("coin", rechargevalue);
      formData.append("payment", "coin");
      formData.append("gameId", id);
      const res = await createRecharge("recharge", formData);
      if (res.status === 201) {
        setUser({ ...user, coin: user?.coin - rechargevalue });
        setSuccessMsg("Request sent successfully.");
        setError("");
        setTimeout(() => {
          handleRechargeSuccess();
          setIsRequestSubmitted(false);
          setSuccessMsg("");
        }, 500);
      } else {
        setError(res.message || "Unable to process request");
      }
    }
  };

  const hidePopup = () => {
    handleRecharge();
    setRechargeInput("");
    setError("");
    setSuccessMsg("");
  };

  const checkInvalidInput = ["+", "-", ".", "e"];

  return (
    <Modal
      centered
      className="process-popup"
      show={recharge}
      size="lg"
      onHide={() => hidePopup()}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="recharge-popup-content">
          <h5>Recharge game </h5>
          <div className="game-recharge-info">
            <div className="game-recharge-left">
              <img src={logo || game} alt="" />
            </div>
            <div className="game-recharge-right">
              <h6>{name || ""}</h6>
              <p>
                <img src={remote} alt="" />
                {playCount || 0} Games
              </p>
            </div>
          </div>
          <div className="total-balance">
            <h6>Your total balance</h6>
            <p>
              <img src={coinicon} alt="logout" />
              {user.coin || 0}
            </p>
          </div>
          <div className="recharge-amount-wrapper">
            <FormControl
              type="number"
              placeholder="Recharge to game"
              min="1"
              step="1"
              onChange={(e) => setRechargeInput(e.target.value)}
              onKeyDown={(event) => {
                if (checkInvalidInput.indexOf(event.key) > -1) {
                  event.preventDefault();
                }
              }}
            />
            <div className="recharge-amount-show">
              <p>
                <img src={coinicon} alt="coinIncon" />
                {rechargeInput || 0}
              </p>
            </div>
          </div>
          {error && <p className="text-danger">{error}</p>}
          {successMsg && <p className="text-success">{successMsg}</p>}
          <div className="popup-footer-btn">
            <Button className="popup-black" onClick={hidePopup}>
              cancel
            </Button>
            <Button
              className="popup-red"
              disabled={isRequestSubmitted}
              onClick={() => handleRechargeClick()}
            >
              Confirm{" "}
              {isRequestSubmitted && (
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const RechargeSuccess = ({
  rechargeSuccess,
  handleRechargeSuccess,
  rechargeInput,
  selectedGame,
}) => {
  const { name, logo } = selectedGame || {};
  return (
    <Modal
      centered
      className="process-popup"
      show={rechargeSuccess}
      size="lg"
      onHide={() => handleRechargeSuccess("rechargeSuccess")}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="recharge-success-content">
          <img src={thumbiocn} alt="icon" className="thumb-icon" />
          <h5>You have successefully recharged to game</h5>
          <div className="game-recharge-info">
            <div className="game-recharge-left">
              <img src={logo || game} alt="" />
            </div>
            <div className="game-recharge-right">
              <h6>{name}</h6>
              <p>
                <img src={coinicon} alt="icon" />
                {rechargeInput || 0}
              </p>
            </div>
          </div>

          <Button
            className="popup-red"
            onClick={() => handleRechargeSuccess("rechargeSuccess")}
          >
            Ok
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
