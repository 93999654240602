import Layout from "../layout/layout";
import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { LoginSchema } from "../../validationSchema/authSchema";
import { login } from "../../utils/Api";
import UserContext from "../../context/UserContext";
import { Form, Button, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import "./login.css";

const Login = () => {
  const navigate = useNavigate();
  const [pass, setPass] = useState("password");
  const handlePass = () => {
    setPass(!pass);
  };
  const { setTokens, setUser, user, token, setKycStatus } =
    useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user && token) {
      navigate("/");
    }
  }, [user, navigate, token]);

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm({ resolver: yupResolver(LoginSchema) });

  const loginUser = async (values) => {
    setIsLoading(true);
    const res = await login(values);
    console.log(res)
    setIsLoading(false);
    if (res.message === "Incorrect email") {
      setError(
        "email",
        { type: "custom", message: "Invalid email" },
        { shouldFocus: true }
      );
    } else if (res.message === "Incorrect password") {
      setError(
        "password",
        { type: "custom", message: "Invalid password" },
        { shouldFocus: true }
      );
    } else if (res.message === "Please verify your account") {
      setError(
        "password",
        { type: "custom", message: "Please verify your account" },
        { shouldFocus: true }
      );
      navigate("/verify-email");
    } else if (res.message === "Not found") {
      setError(
        "password",
        { type: "custom", message: "Invalid request" },
        { shouldFocus: true }
      );
    } else if (res.user) {
      localStorage.setItem("token", JSON.stringify(res.tokens));
      localStorage.setItem("userId", res?.user?.id);
      setTokens(res.tokens);
      setUser(res.user);
      if (res.KycStatus) {
        setKycStatus(res.KycStatus);
      }
      toast.success("Login Successfully");
      navigate("/client-profile");
    }
  };

  return (
    <Layout>
      <div className='login'>
        <div className='auth-page'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='login-form'>
                  <h1>Login</h1>
                  <p className='auth-para'>
                    Welcome back to King of Fish Games
                  </p>
                  <div className='login-box'>
                    <Form onSubmit={handleSubmit(loginUser)}>
                      <Form.Group className='form-group'>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type='email'
                          name='email'
                          className={errors.email ? "error-field" : ""}
                          {...register("email")}
                          autoComplete='off'
                        />
                        {errors?.email ? (
                          <p className='error-text'>{errors.email.message}</p>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                      <Form.Group className='form-group'>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type={`${pass ? `password` : `text`}`}
                          name='password'
                          className={errors.password ? "error-field" : ""}
                          {...register("password")}
                          autoComplete='off'
                        />
                        <span className='input-icon' onClick={handlePass}>
                          <i
                            class={
                              pass ? "las la-eye" : "las la-eye-slash"
                            }></i>
                        </span>
                        {errors?.password ? (
                          <p className='error-text'>
                            {errors.password.message}
                          </p>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                      <div className='forget-password'>
                        <div className='remember-me'>
                          <Form.Check
                            type='checkbox'
                            {...register("remember")}
                            label='Remember me'
                          />
                        </div>
                        <Link to='/forget-password'>Forget Password?</Link>
                      </div>
                      <div className='login-button'>
                        <Button type='submit' className='l-btn'>
                          {isLoading ? <Spinner animation='border' /> : "Login"}
                        </Button>
                      </div>
                    </Form>
                    <p className='already'>
                      Don't have an account yet?{" "}
                      <Link to='/register' className='forget-link'>
                        Register
                      </Link>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
