import { useEffect, useRef, useState } from "react";
import "./layout.css";
import ClientMenu from "../menu/menu";
import ClientHeader from "../clientheader/header";
import chaticon from "../../../assets/images/chat/chat-icon.svg";
import SupportChat from "../../supportchat/chat";

const ClientLayout = ({ children }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [show, setShow] = useState(false);
  const handleClick = () => {
    setShow(!show);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div className="main-wrapper">
      <div className="admin-content-view">
        <ClientHeader />

        <div className="admin-wrapper">
          <ClientMenu />

          <div className="admin-container">
            <div className="client-content">{children}</div>
          </div>
          <div className="support-chat-wrapper me-4" ref={wrapperRef}>
            <div className="support-icon">
              <span
                role="presentation"
                onClick={() => handleClick(!show)}
                id="liveChat"
              >
                <img src={chaticon} alt="chat" />
              </span>
            </div>

            {show && <SupportChat show={show} handleClick={handleClick} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientLayout;
