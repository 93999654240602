import { useState, useEffect, useRef } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import { socket } from "../../config/socket";
import user from "../../assets/images/chat/user.png";
import userImg from "../../assets/images/chat/user3.png";
import cancel from "../../assets/images/chat/close-icon.svg";
import send from "../../assets/images/chat/send-icon.svg";
import "./support.css";
import { getTime } from "../../utils";

const SupportChat = ({ show, handleClick }) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [msg, setMsg] = useState("");
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleSendMsg = (e) => {
    e.preventDefault();
    const messageData = {
      message: msg,
      isAdmin: false,
      userId: localStorage.getItem("userId"),
    };
    socket.emit("userToAdmin", messageData);
    setChatMessages([...chatMessages, messageData]);
    setMsg("");
    scrollToBottom("chatBox");
  };
  useEffect(() => {
    socket.on("newAdminMsg", (data) => {
      setChatMessages([...chatMessages, data.data]);
      scrollToBottom("chatBox");
    });

    return () => {
      socket.off("adminReply");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMessages]);
  const [chatLoading, setChatLoading] = useState(false);
  useEffect(() => {
    socket.emit(
      "getChat",
      { userId: localStorage.getItem("userId") },
      function (received_excerpt) {
        if (received_excerpt) {
          setChatLoading(true);
          // resolve(received_excerpt);
          setTimeout(() => {
            setChatMessages(received_excerpt);
            setChatLoading(false);
          }, 1500);
        } else {
          console.log("Unable to fetch chat");
        }
      }
    );
  }, []);
  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  return (
    <div className="support-box">
      <div className="support-header">
        <div className="support-title-sec">
          <div className="user-img">
            <img src={user} alt="user" />
          </div>
          <div className="support-heading">
            <h6>24/7 Support</h6>
          </div>
        </div>
        <div className="cancel-support">
          <span onClick={() => handleClick(!show)}>
            <img src={cancel} alt="cancel" />
          </span>
        </div>
      </div>

      <div className="support-body">
        {!chatLoading ? (
          <>
            <div className="support-section" id="chatBox">
              {chatMessages && chatMessages.length > 0 ? (
                <>
                  {chatMessages.map((el, i) => {
                    console.log(el);
                    return (
                      <div
                        className={
                          el.isAdmin ? "user-message" : "admin-message"
                        }
                        ref={messagesEndRef}
                        key={el?.id ?? el?._id}
                      >
                        {el.isAdmin && (
                          <div className="user-message-icon">
                            <img src={user} alt="user" />
                          </div>
                        )}
                        <div className="message-section d-flex gap-2 align-items-start">
                          <div>
                            <div className="message-box ">
                              <h6 className="text-wrap">{el.message}</h6>
                            </div>
                            <p>{getTime(el?.createdAt)}</p>
                          </div>
                          {!el.isAdmin && (
                            <div className="user-message-icon">
                              <img src={userImg} alt="user" />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <p>No chats</p>
                </>
              )}
            </div>
            <div className="support-input-section">
              <Form onSubmit={(e) => handleSendMsg(e)}>
                <div className="support-input">
                  <Form.Group className="form-group">
                    <Form.Control
                      type="text"
                      placeholder="Write your question"
                      value={msg}
                      onChange={(e) => setMsg(e.target.value)}
                      required
                    />
                  </Form.Group>
                </div>
                <div className="send-support">
                  <Button type="submit">
                    <img src={send} alt="send" />
                  </Button>
                </div>
              </Form>
            </div>
          </>
        ) : (
          <div className="chat_spinner_container">
            <Spinner animation="border" variant="dark" />
          </div>
        )}
      </div>
    </div>
  );
};

SupportChat.propTypes = {
  show: PropTypes.bool,
  handleClick: PropTypes.func,
};
export default SupportChat;
