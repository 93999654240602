import { useContext, useEffect, useState } from "react";
import userImg from "../../../assets/images/wallet/client.svg";
import edit from "../../../assets/images/wallet/edit.svg";
import newplayer from "../../../assets/images/wallet/newplayer.svg";
import loyal from "../../../assets/images/wallet/loyalplayer.svg";
import superloyal from "../../../assets/images/wallet/superloyal.svg";
import first from "../../../assets/images/wallet/first.svg";
import second from "../../../assets/images/wallet/second.svg";
import third from "../../../assets/images/wallet/third.svg";
import tick from "../../../assets/images/wallet/bluetick.svg";
import game from "../../../assets/images/wallet/game.svg";
import remote from "../../../assets/images/wallet/gameremote.svg";
import ClientLayout from "../clientlayout/clientlayout";
import WalletMenu from "../menu/walletmenu";
import "./wallet.css";
import { Button } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import CoinPopup from "../coinpopup/coinpopup";
import { Link } from "react-router-dom";
import UserContext from "../../../context/UserContext";
import {
  fetchRecentGame,
  getAdminCashApp,
  getProgressStats,
} from "../../../utils/Api";
import { getLevel, getLevelStatus, timeGreet } from "../../../utils";
import { CircularProgressbar } from "react-circular-progressbar";
import GameInfoPopup from "../my-game/gameInfoPopup";
import KYCForm from "../../kyc/kycForm";

const Wallet = () => {
  const { setAdminDetail, user, kycStatus } = useContext(UserContext);
  const [coin, setCoin] = useState(false);
  const [openKYCModal, setOpenKYCModal] = useState(false);
  const handleClose = () => setCoin(false);
  const [recentGames, setRecentGames] = useState([]);
  const [levelData, setLevelData] = useState({
    amountNeed: 1,
    levelOnePer: 0,
    levelTwoPer: 0,
    levelThreePer: 0,
    levelOneAmount: 0,
    levelTwoAmount: 0,
    levelThreeAmount: 0,
    totalAmount: 0,
    circularBarPer: 0,
  });
  useEffect(() => {
    const getAdminData = async () => {
      const res = await getAdminCashApp();
      if (res.code === 200) {
        setAdminDetail(res.userData);
      }
    };
    getAdminData();
  }, [setAdminDetail]);

  useEffect(() => {
    const getProgress = async () => {
      const res = await getProgressStats();
      if (res.status === 200) {
        const { progress } = res.data;
        if (progress.length > 0) {
          const { usdAmount } = progress[0] || {};
          const levelData = getLevel(usdAmount || 0);
          setLevelData(levelData);
        }
      }
    };
    const getRecenetGames = async () => {
      const res = await fetchRecentGame();
      if (res.status === 200) {
        const { data } = res.data;
        setRecentGames(data);
      }
    };
    getProgress();
    getRecenetGames();
  }, []);

  const [gameInfo, setGameInfo] = useState(false);
  const handleGameInfo = () => {
    setGameInfo(!gameInfo);
  };
  const [selectedGame, setSelectedGame] = useState(null);
  const handleSelectGame = (data) => {
    if (user?.coin <= 0) {
      setCoin(true);
    } else {
      setSelectedGame(data);
      handleGameInfo();
    }
  };

  const handleKyc = () => {
    setOpenKYCModal(!openKYCModal);
  };

  return (
    <ClientLayout>
      <div className="client-profile-page">
        <div className="container">
          <div className="wallet-page-content">
            <div className="wallet-page">
              <h1>Wallet</h1>
              <div className="wallet-user-content">
                <div className="wallet-user">
                  <div className="wallet-user-left">
                    <div className="wallet-userimg">
                      <img src={user?.avatar || userImg} alt="" />
                    </div>
                    <div className="wallet-user-text">
                      <h2>
                        {timeGreet()}, {user?.nickName}
                      </h2>
                      <a href={`mailto:${user?.email || ""}`}>
                        {user?.email || ""}
                      </a>
                    </div>
                  </div>
                  <Link to="/setting" className="wallet-user-edit">
                    <img src={edit} alt="edit" />
                  </Link>
                </div>
              </div>
              <div className="d-flex flex-column flex-lg-row flex-md-row mb-3 mb-lg-0 mb-md-0">
                <p>
                  To access all features of our website, please complete the KYC
                  (Know Your Customer) process. We require every customer to
                  verify their identity to ensure that you are the rightful
                  account holder and to make sure that you’re 21+ in order to
                  play on our website. Rest assured, your information will be
                  used solely for verification purposes and will not be used for
                  any other reasons.
                </p>
                <div className="wallet-balance-btn">
                  <Button
                    className={`${
                      kycStatus === "accept"
                        ? "green-btn"
                        : kycStatus === "idle"
                        ? "blue-btn"
                        : kycStatus === "reject"
                        ? "red-btn"
                        : "red-btn"
                    }`}
                    onClick={handleKyc}
                    disabled={kycStatus}
                  >
                    {kycStatus === "accept"
                      ? "Verified"
                      : kycStatus === "idle"
                      ? "Processing"
                      : kycStatus === "reject"
                      ? "Rejected"
                      : "Verify"}
                  </Button>
                </div>
              </div>

              <div className="wallet-user-level">
                <div className="wallet-user-level-left">
                  <p>Your Curent level</p>
                  <div className="current-level-pro">
                    <CircularProgressbar
                      value={levelData.circularBarPer || 0}
                    />
                    <div className="current-level-img">
                      <img
                        src={
                          levelData.levelTwoPer >= 100 &&
                          levelData.levelOnePer >= 100 &&
                          levelData.levelThreePer >= 100
                            ? superloyal
                            : levelData.levelOnePer >= 100 &&
                              levelData.levelTwoPer >= 100 &&
                              levelData.levelThreePer < 100
                            ? loyal
                            : newplayer
                        }
                        alt="level"
                      />
                    </div>
                  </div>
                  <h3>{getLevelStatus(levelData.totalAmount)}</h3>
                  <span>
                    Deposit ${levelData.amountNeed || 0} more to get to a new
                    level
                  </span>
                </div>
                <div className="wallet-user-level-right">
                  <div className="wallet-user-progress">
                    <h3>Your progress</h3>
                    {/* new player */}
                    <div className="wallet-user-progress">
                      <ProgressBar now={levelData.levelOnePer || 0} />
                      <div className="wallet-user-progress-item">
                        <div className="wallet-user-newplayer">
                          <div className="user-newplayer">
                            <div className="user-newplayer-number">
                              <img
                                src={
                                  levelData.levelOnePer >= 100 ? tick : first
                                }
                                alt="tick"
                              />
                            </div>
                            <div className="user-newplayer-img">
                              <img src={newplayer} alt="player" />
                            </div>
                            <div className="user-newplayer-text">
                              <h4>New Player</h4>
                              <p>from $1 deposit</p>
                            </div>
                          </div>
                          <div className="user-newplayer-prize">
                            <span>${levelData.levelOneAmount || 0}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Loyal Player */}
                    <div className="wallet-user-progress">
                      <ProgressBar now={levelData.levelTwoPer || 0} />
                      <div className="wallet-user-progress-item">
                        <div className="wallet-user-newplayer">
                          <div className="user-newplayer">
                            <div className="user-newplayer-number">
                              <img
                                src={
                                  levelData.levelTwoPer >= 100 ? tick : second
                                }
                                alt="second"
                              />
                            </div>
                            <div className="user-newplayer-img">
                              <img src={loyal} alt="player" />
                            </div>
                            <div className="user-newplayer-text">
                              <h4>Loyal Player</h4>
                              <p>$1000 deposit</p>
                            </div>
                          </div>
                          <div className="user-newplayer-prize">
                            <span>${levelData.levelTwoAmount || 0}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Super loyal player */}
                    <div className="wallet-user-progress">
                      <ProgressBar now={levelData.levelThreePer || 0} />
                      <div className="wallet-user-progress-item">
                        <div className="wallet-suparloyal-newplayer">
                          <div className="wallet-user-newplayer">
                            <div className="user-newplayer">
                              <div className="user-newplayer-number">
                                <img
                                  src={
                                    levelData.levelThreePer >= 100
                                      ? tick
                                      : third
                                  }
                                  alt="third"
                                />
                              </div>
                              <div className="user-newplayer-img">
                                <img src={superloyal} alt="player" />
                              </div>
                              <div className="user-newplayer-text">
                                <h4>Super Loyal Player</h4>
                                <p>$8000 deposit</p>
                              </div>
                            </div>
                            <div className="user-newplayer-prize">
                              <span>${levelData.levelThreeAmount || 0}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {recentGames.length > 0 && (
                <>
                  <div className="wallet-user-recent-game">
                    <h2>Recent games</h2>
                    <div className="wallet-user-recent-game-grid">
                      {recentGames.map((recentGame) => (
                        <div
                          className="recent-game_box"
                          key={recentGame._id ?? recentGame?.id}
                        >
                          <img
                            src={recentGame?.gameDetail[0]?.logo || game}
                            alt=""
                          />
                          <div className="recent-game-detail">
                            <div className="recent-game-detail-bottom">
                              <div className="recent-game-detail-left">
                                <p>{recentGame?.gameDetail[0]?.name || ""}</p>
                                <div className="recent-game-detail-img">
                                  <img src={remote} alt="" />
                                  <span>{recentGame?.userPlayCount} Games</span>
                                </div>
                              </div>
                              <div className="recent-game-detail-right">
                                <Button
                                  className="red-btn"
                                  onClick={() =>
                                    handleSelectGame(recentGame?.gameDetail[0])
                                  }
                                >
                                  Play
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
            <WalletMenu />
            {gameInfo && (
              <GameInfoPopup
                handleGameInfo={handleGameInfo}
                gameInfo={gameInfo}
                selectedGame={selectedGame}
              />
            )}
          </div>
        </div>
        {coin && <CoinPopup coin={coin} handleClose={handleClose} />}
        {openKYCModal && (
          <KYCForm openCloseKYCForm={handleKyc} openKYCModal={openKYCModal} />
        )}
      </div>
    </ClientLayout>
  );
};

export default Wallet;
