import {
  authInstance,
  userInstance,
  transactionInstance,
  gameInstance,
  brandInstance,
  offerInstance,
  notificationInstance,
  adminInstance,
} from "../config/axios";

export const getAllUsers = async (setLiveUsers) => {
  try {
    const res = await userInstance().get(`/getUsers`);
    console.log("dfdfd =>", res.data);
    setLiveUsers(res.data.results);
    return res.data;
  } catch (error) {
    console.log("Error in getAllUsers api =>", error);
    return error.response.data;
  }
};

export const login = async (values) => {
  try {
    const res = await authInstance().post(`/login`, values);
    return res.data;
  } catch (error) {
    console.log("Error in login api =>", error);
    return error.response.data;
  }
};

export const signup = async (values) => {
  try {
    const res = await authInstance().post(`/register`, values);
    return res.data;
  } catch (error) {
    console.log("Error in singup api =>", error);
    return error.response.data;
  }
};

export const sendVerificationEmail = async (values) => {
  try {
    const res = await authInstance().post(`/send-verification-email`, values);
    return res;
  } catch (error) {
    console.log("Error in singup api =>", error);
    return error.response.data;
  }
};

export const verifyEmail = async (data) => {
  try {
    const res = await authInstance().post(`/verify-email`, data);
    return res.data;
  } catch (error) {
    console.log("Error in singup api =>", error);
    return error.response.data;
  }
};

export const forgetPassword = async (values) => {
  try {
    const res = await authInstance().post(`/forgot-password`, values);
    return res.data;
  } catch (error) {
    console.log("Error in forgetPassword api =>", error);
    return error.response.data;
  }
};

export const resetPassword = async (values, email) => {
  try {
    values.email = email;
    const res = await authInstance().post(`/reset-password`, values);
    return res.data;
  } catch (error) {
    console.log("Error in resetPassword api =>", error);
    return error.response.data;
  }
};
export const sendVerificationCode = async (email) => {
  try {
    const res = await authInstance().post(`/send-verification-code`, { email });
    return res.data;
  } catch (error) {
    console.log("Error in send-verification-code api =>", error);
    return error.response.data;
  }
};
export const verifyVerificationCode = async (data) => {
  try {
    const res = await authInstance().post(`/verify-verification-code`, data);
    return res.data;
  } catch (error) {
    console.log("Error in verify-verification-code api =>", error);
    return error.response.data;
  }
};

export const getUser = async (cb) => {
  try {
    const res = await userInstance().get(`/`);
    return cb({
      success: true,
      user: res.data.user,
      KycStatus: res.data.KycStatus,
    });
  } catch (error) {
    console.log("Error in getUser api =>", error);
    return cb({ success: false, data: error.response.data });
  }
};

export const updateUser = async (formData, method) => {
  try {
    const res = await userInstance().post(`/update/${method}`, formData);
    return res;
  } catch (error) {
    console.log("Error in updateUser api =>", error);
    return error.response.data;
  }
};

export const contactUsApi = async (values) => {
  try {
    const res = await userInstance().post(`/save-customer-contact`, values);
    console.log(res);
    return { ...res.data, status: res.status };
  } catch (error) {
    console.log("Error in singup api =>", error);
    return error.response.data;
  }
};
export const uplaodImage = async (formData) => {
  try {
    const res = await userInstance().post(`/upload-image`, formData);
    console.log(res);
    return res;
  } catch (error) {
    console.log("Error in image uplaod api =>", error);
    return error.response.data;
  }
};

export const PostDistributorDataApi = async (values) => {
  try {
    const res = await userInstance().post(`/create-distributor`, values);
    return { ...res.data, status: res.status };
  } catch (error) {
    console.log("Error in singup api =>", error);
    return error.response.data;
  }
};

export const createTransaction = async (transactionType, values) => {
  try {
    const res = await transactionInstance().post(`/${transactionType}`, values);
    return res.data;
  } catch (error) {
    console.log("Error in singup api =>", error);
    return error.response.data;
  }
};
export const getAdminCashApp = async () => {
  try {
    const res = await transactionInstance().get(`/cashAppId/admin`);
    return res.data;
  } catch (error) {
    console.log("Error in singup api =>", error);
    return error.response.data;
  }
};

export const getGames = async (paginationOptions) => {
  try {
    const { limit, page, sort, gamesCat, gameId, brandName, name } =
      paginationOptions || {};
    const sortBy = `sortBy=${sort}`;
    const paginationQuery = `limit=${limit}&page=${page || 1}`;
    const gameCategory = `&gameCategory=${gamesCat}`;
    const checkCat =
      gamesCat === "slots" ||
      gamesCat === "fish" ||
      gamesCat === "keno" ||
      gamesCat === "others"
        ? true
        : false;
    const _id = gameId ? `&_id[ne]=${gameId}` : "";
    const brandNameQuery = brandName ? `&brandName=${brandName}` : "";
    const searchQuery = name ? `&name=${name}` : ``;
    const res = await gameInstance().get(
      `?${sortBy}&${paginationQuery}${
        checkCat ? gameCategory : ""
      }${_id}${brandNameQuery}${searchQuery}`
    );
    return res;
  } catch (error) {
    console.log("Error in get games api =>", error);
    return error.response.data;
  }
};

export const createRecharge = async (transactionType, paylaod) => {
  try {
    const res = await transactionInstance().post(
      `/${transactionType}`,
      paylaod
    );
    return res;
  } catch (error) {
    console.log("Error in getChats api =>", error);
    return error.response.data;
  }
};
export const getTransactions = async (transactionType, paginationOptions) => {
  try {
    const { limit, page, searchValue, _id } = paginationOptions;
    const statusQuery = `&status=accept&status=reject`;
    const transactionQuery = transactionType
      ? `transaction=${transactionType}`
      : "";
    const paginationQuery = `&limit=${limit || 10}&page=${page || 1}`;
    const searchQuery = searchValue ? `&payment=${searchValue}` : "";
    const sortBy = `&sortBy=-createdAt`;
    const userId = _id ? `&userId=${_id}` : "";
    const res = await transactionInstance().get(
      `/${transactionType}?${transactionQuery}${statusQuery}${paginationQuery}${sortBy}${userId}${searchQuery}`
    );
    return res;
  } catch (error) {
    console.log("Error in getChats api =>", error);
    return error.response.data;
  }
};

export const getBrands = async (paginationOptions) => {
  try {
    const { limit, page } = paginationOptions;
    // const queryMySearchString = name ? `&name=${name}` : ``;
    // const queryMyStatus = status ? `&status=${status}` : ``;
    const res = await brandInstance().get(`?limit=${limit}&page=${page}`);
    console.log("get Live data----->", res);
    return res;
  } catch (error) {
    console.log("Error in get brands api =>", error);
    return error.response.data;
  }
};

export const getBrandsByGame = async (paginationOptions) => {
  try {
    const { limit, page, brandId } = paginationOptions;
    // const queryMySearchString = name ? `&name=${name}` : ``;
    // const queryMyStatus = status ? `&status=${status}` : ``;
    const brandName = brandId ? `&brandName=${brandId}` : "";
    const res = await brandInstance().get(
      `/brands-by-game?limit=${limit}&page=${page}${brandName}`
    );
    // console.log("get Live data----->", res);
    return res;
  } catch (error) {
    console.log("Error in get brands api =>", error);
    return error.response.data;
  }
};

export const getSingleGame = async (_id) => {
  try {
    const res = await gameInstance().get(`/getGameById/${_id}`);
    return res;
  } catch (error) {
    console.log("Error in get brands api =>", error);
    return error.response.data;
  }
};

export const createGameRequest = async (requestData) => {
  try {
    const res = await gameInstance().post(`/game-request`, requestData);
    // console.log("get Live data----->", res);
    return res;
  } catch (error) {
    console.log("Error in get brands api =>", error);
    return error.response.data;
  }
};

export const fetchGameRequest = async (paginationOptions) => {
  try {
    const { limit, page, status, searchValue } = paginationOptions;
    const statusQuery = `status=${status}`;
    const paginationQuery = `limit=${limit || 10}&page=${page || 1}`;
    const sortBy = `&sortBy=-createdAt`;
    const name = searchValue ? `&name=${searchValue}` : "";
    const res = await gameInstance().get(
      `/game-request?${statusQuery}&${paginationQuery}${sortBy}${name}`
    );
    // console.log("get Live data----->", res);
    return res;
  } catch (error) {
    console.log("Error in get brands api =>", error);
    return error.response.data;
  }
};

export const getProgressStats = async () => {
  try {
    const res = await userInstance().get(`/progress-stats`);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const fetchOffers = async (paginationOptions) => {
  try {
    const { limit, page, name, description } = paginationOptions;
    const nameQuery = name ? `&name=${name}` : "";
    const descriptionQuery = description ? `&description=${description}` : "";
    const paginationQuery = `limit=${limit || 10}&page=${page || 1}`;
    const sortBy = `&sortBy=-createdAt`;
    const res = await offerInstance().get(
      `/?${paginationQuery}${sortBy}${nameQuery}${descriptionQuery}`
    );
    return res;
  } catch (error) {
    console.log("Error in get offers api =>", error);
    return error.response.data;
  }
};

export const getNotifications = async () => {
  try {
    const res = await notificationInstance().get();
    return res;
  } catch (error) {
    console.log("error in getnotification----->>", error);
    return error.response.data;
  }
};
export const updateNotification = async (notificationIds) => {
  try {
    const res = await notificationInstance().patch("/", notificationIds);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const createRecentGame = async (gameId) => {
  try {
    const res = await gameInstance().post("/recent-game", { gameId });
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const fetchRecentGame = async () => {
  try {
    const res = await gameInstance().get("/recent-game");
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const fetchRecentGameBySearch = async (searchObj) => {
  try {
    let { searchValue, limit, page } = searchObj;
    searchValue = `searchValue=${searchValue}`;
    limit = `&limit=${limit}`;
    page = `&page=${page}`;
    const res = await gameInstance().get(
      `/game-request-search?${searchValue}${limit}${page}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const createKYC = async (requestData) => {
  try {
    const res = await userInstance().post(`/kyc`, requestData);
    console.log("get KYC data----->", res);
    return res;
  } catch (error) {
    console.log("Error in KYC brands api =>", error);
    return error.response.data;
  }
};

export const createVeriffKYC = async (requestData) => {
  try {
    console.log(requestData);
    const res = await userInstance().post(`/submit-veriff-kyc`, requestData);
    console.log("get KYC data----->", res);
    return res;
  } catch (error) {
    console.log("Error in KYC brands api =>", error);
    return error.response.data;
  }
};

export const getImageSetting = async () => {
  try {
    const res = await adminInstance().get("settings");
    return res;
  } catch (error) {
    console.log("Error in getImageSettings api =>", error);
    return error.response.data;
  }
};
