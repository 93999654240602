import { useState, useContext } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
// import cashicon from "../../../assets/images/table/cash-app-black.svg";
// import qricon from "../../../assets/images/table/qr-icon.png";
import linkicon from "../../../assets/images/table/link-icon.svg";
import { createTransaction } from "../../../utils/Api";
import UserContext from "../../../context/UserContext";
import "../withdraw/withdraw.css";
// import { errorMsgTimeout } from "../../../utils";

const DepositConfirmPopup = ({
  handleShowDeposit,
  showDeposit,
  depositData,
  setDepositData,
  steps,
  setSteps,
}) => {
  const { adminDetail } = useContext(UserContext);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [files, setFiles] = useState([]);
  const [cashAppPaymentLink, setCashAppPaymentLink] = useState("");
  const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);

  const handleStepChange = () => {
    setSteps(1);
  };
  const handleSubmit = async () => {
    // const payLoad = {...depositData, coin:parseInt(depositData.coin)}
    const formData = new FormData();
    setIsRequestSubmitted(true);
    formData.append("coin", parseInt(depositData.coin));
    formData.append("payment", depositData.payment);
    files.forEach((file) => {
      formData.append("screenShots", file);
    });
    formData.append("paymentRecipient", cashAppPaymentLink);

    const res = await createTransaction("deposit", formData);
    if (res.code === 200) {
      setSuccessMsg("Deposit request send.");
      // setUser({...user, coin:userAmount})
      setFiles([]);
      setTimeout(() => {
        setErrorMsg("");
        hidePopup();
        setIsRequestSubmitted(false);
      }, 500);
    } else {
      setErrorMsg("Unable to deposit.");
      setTimeout(() => {
        setErrorMsg("");
      }, 500);
    }
  };

  const hidePopup = () => {
    setFiles([]);
    setErrorMsg("");
    setSuccessMsg("");
    handleShowDeposit();
    setDepositData({
      coin: "",
      amountInUsd: "",
      cryptoPrice: "",
      payment: "cashApp",
      status: "idle",
    });
  };

  const handleFiles = (newFiles) => {
    if (files.length + newFiles.length > 3) {
      alert("You can only upload a maximum of 3 images.");
      return;
    }
    const validFiles = Array.from(newFiles).filter((file) =>
      file.type.startsWith("image/")
    );
    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    handleFiles(droppedFiles);
  };

  const handleChange = (e) => {
    const selectedFiles = e.target.files;
    handleFiles(selectedFiles);
  };

  const removeFile = (e, index) => {
    e.stopPropagation();
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };
  return (
    <Modal
      size="lg"
      className="withdarw-popup"
      show={showDeposit}
      onHide={() => hidePopup()}
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="transaction-body">
        <Modal.Body>
          <div className="transaction-body-content">
            {/* <img src={cashicon} alt="cashapp" /> */}
            {steps === 0 ? (
              <div>
                <div className="transaction-heading">
                  <h6>Please confirm money withdrawal to client account</h6>
                  {depositData.payment === "cashApp" ||
                  "venmo" ||
                  "paypal" ||
                  "chime" ? (
                    <p>${depositData.amountInUsd}</p>
                  ) : (
                    <p>BTC {depositData.cryptoPrice}</p>
                  )}
                </div>
                {(depositData.payment === "cashApp" ||
                  depositData.payment === "venmo" ||
                  depositData.payment === "chime" ||
                  depositData.payment === "paypal") && (
                  <>
                    <div className="cashtag-link">
                      {depositData.payment === "cashApp" ? (
                        <>
                          <h6>
                            Cashtag: <span>{adminDetail?.cashTagId}</span>
                          </h6>
                          <span className="link-icon">
                            <img src={linkicon} alt="link-icon" />
                          </span>
                        </>
                      ) : depositData.payment === "venmo" ? (
                        <>
                          <h6>
                            Venmo: <span>{adminDetail?.venmoId}</span>
                          </h6>
                          <span className="link-icon">
                            <img src={linkicon} alt="link-icon" />
                          </span>
                        </>
                      ) : depositData.payment === "chime" ? (
                        <>
                          <h6>
                            Chime: <span>{adminDetail?.chimeId}</span>
                          </h6>
                          <span className="link-icon">
                            <img src={linkicon} alt="link-icon" />
                          </span>
                        </>
                      ) : (
                        <>
                          <h6>
                            Paypal: <span>{adminDetail?.paypalId}</span>
                          </h6>
                          <span className="link-icon">
                            <img src={linkicon} alt="link-icon" />
                          </span>
                        </>
                      )}
                    </div>
                  </>
                )}

                {depositData.payment === "btc" && adminDetail?.btcAddress && (
                  <>
                    <div className="cashtag-link">
                      <h6>
                        Wallet: <span>{adminDetail?.btcAddress}</span>
                      </h6>
                      <span className="link-icon">
                        <img src={linkicon} alt="link-icon" />
                      </span>
                    </div>
                  </>
                )}

                <div className="qr-wrapper">
                  {depositData.payment === "cashApp" && (
                    <>
                      <div className="qr-box">
                        <img src={adminDetail?.cashTagImage} alt="cashapp" />
                      </div>
                    </>
                  )}
                  {depositData.payment === "venmo" && (
                    <>
                      <div className="qr-box">
                        <img src={adminDetail?.venmoImage} alt="cashapp" />
                      </div>
                    </>
                  )}
                  {depositData.payment === "paypal" && (
                    <>
                      <div className="qr-box">
                        <img src={adminDetail?.paypalImage} alt="cashapp" />
                      </div>
                    </>
                  )}
                  {depositData.payment === "chime" && (
                    <>
                      <div className="qr-box">
                        <img src={adminDetail?.chimeImage} alt="cashapp" />
                      </div>
                    </>
                  )}
                  {depositData.payment === "btc" &&
                    adminDetail?.btcAddressImage && (
                      <>
                        <div className="qr-box">
                          <img
                            src={adminDetail?.btcAddressImage}
                            alt="cashapp"
                          />
                        </div>
                      </>
                    )}
                  <div className="qr-info">
                    <h6>{adminDetail?.name}</h6>
                    {((depositData.payment === "btc" &&
                      adminDetail?.btcAddressImage) ||
                      depositData.payment === "cashApp") && (
                      <p>Scan to pay {adminDetail?.name}</p>
                    )}
                    {errorMsg && (
                      <>
                        <h6 className="text-danger mt-2 pb-1">{errorMsg}</h6>
                      </>
                    )}
                    {successMsg && (
                      <>
                        <h6 className="text-success mt-2 pb-1">{successMsg}</h6>
                      </>
                    )}
                  </div>
                </div>
                <div className="game-box-btn">
                  <div className="transparent-btn">
                    <Button onClick={hidePopup}>Cancel</Button>
                  </div>
                  <div className="red-btn">
                    <Button onClick={handleStepChange}>Next</Button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="transaction-heading">
                  <h6>Please Upload Screenshot for Payment</h6>
                </div>
                {/* {depositData.payment === "cashApp" && ( */}
                <>
                  <div className="p-2">
                    <div className="cashtag-link">
                      <h6 className="d-flex align-items-center">
                        <lable className="col-4">Receipt Link: </lable>
                        <input
                          className="border p-2 rounded-2 ms-1 bg-transparent w-100 payment-link"
                          value={cashAppPaymentLink}
                          // placeholder="Receipt Link"
                          onChange={(e) =>
                            setCashAppPaymentLink(e.target.value)
                          }
                        />
                      </h6>
                      <span className="link-icon">
                        <img src={linkicon} alt="link-icon" />
                      </span>
                    </div>
                  </div>
                </>
                {/* )} */}
                <div className="qr-wrapper p-2">
                  {/* {depositData.payment === "cashApp" && ( */}
                  <>
                    <div
                      className="upload-image-box p-5"
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={handleDrop}
                      onClick={() =>
                        document.getElementById("file-input").click()
                      }
                    >
                      {files.length <= 0 && (
                        <p>
                          <b>Choose a file</b> or Drag it here
                        </p>
                      )}
                      <input
                        type="file"
                        id="file-input"
                        multiple
                        accept=".png,.jpg,.jpeg,.svg"
                        onChange={handleChange}
                      />
                      <div className="preview d-flex gap-1 justify-content-center align-items-center">
                        {files?.map((file, index) => {
                          return (
                            <>
                              <img
                                key={index}
                                src={URL.createObjectURL(file)}
                                alt={`preview ${index}`}
                              />
                              <button
                                className="remove-button position-relative  border-0 bg-danger rounded-circle"
                                onClick={(e) => removeFile(e, index)}
                              >
                                x
                              </button>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </>
                  {/* )} */}

                  <div className="qr-info">
                    {errorMsg && (
                      <>
                        <h6 className="text-danger mt-2 pb-1">{errorMsg}</h6>
                      </>
                    )}
                    {successMsg && (
                      <>
                        <h6 className="text-success mt-2 pb-1">{successMsg}</h6>
                      </>
                    )}
                  </div>
                </div>
                <div className="game-box-btn">
                  <div className="transparent-btn">
                    <Button onClick={hidePopup}>Cancel</Button>
                  </div>
                  <div className="red-btn">
                    <Button
                      disabled={
                        (!cashAppPaymentLink && files.length === 0) ||
                        isRequestSubmitted
                      }
                      onClick={handleSubmit}
                    >
                      Submit{" "}
                      {isRequestSubmitted && (
                        <Spinner animation="border" role="status" size="sm">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default DepositConfirmPopup;
