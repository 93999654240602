import React, { useContext, useEffect } from "react";
import UserContext from "../../context/UserContext";
import { OtpSchema } from "../../validationSchema/authSchema";
import { useForm } from "react-hook-form";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { sendVerificationEmail, verifyEmail } from "../../utils/Api";

function VerifyOTP({
  checkEmailInput,
  setVerifyOtpPopup,
  verifyOtpPopup,
  setTimeLeft,
  timeLeft,
  setIsLoading,
  isLoading,
}) {
  const navigate = useNavigate();
  const { setTokens, setUser, setKycStatus } = useContext(UserContext);
  const {
    handleSubmit: handleOtpSubmit,
    register: registerOtp,
    formState: { errors: otpErrors },
  } = useForm({ resolver: yupResolver(OtpSchema) });

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  useEffect(() => {
    let interval = null;
    if (timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [timeLeft]);

  const handleOtpVerification = async (values) => {
    setIsLoading(true);

    const res = await verifyEmail({ ...values, email: checkEmailInput });
    if (res.user) {
      setIsLoading(false);
      setVerifyOtpPopup(false);
      localStorage.setItem("token", JSON.stringify(res.tokens));
      localStorage.setItem("userId", res?.user?.id);
      setTokens(res.tokens);
      setUser(res.user);
      if (res.KycStatus) {
        setKycStatus(res.KycStatus);
      }
      toast.success("Login Successfully");
      navigate("/client-profile");
    } else {
      toast.error("Invalid OTP", {
        duration: 3000,
      });
    }
  };
  const reSentOTP = async () => {
    const res = await sendVerificationEmail({ email: checkEmailInput });
    setIsLoading(false);
    if (res.status === 204) {
      setTimeLeft(600);
    } else {
      toast.error(res.message);
    }
  };

  return (
    <Modal
      centered
      className="distributor-popup"
      show={verifyOtpPopup}
      size="xl"
      onHide={() => setVerifyOtpPopup(false)}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="distributor-popup-content">
          <h6>Verify Your OTP</h6>
          <p>
            Enter the OTP sent to your contact to verify your account.
            {/* Request a new one if needed. */}
          </p>
          <div className="distributor-popup-form">
            {timeLeft > 0 ? (
              <Form onSubmit={handleOtpSubmit(handleOtpVerification)}>
                <Form.Group className="form-group">
                  <Form.Label className="d-flex justify-content-between mb-0">
                    <span>OTP</span>
                    <span>{formatTime(timeLeft)}</span>
                  </Form.Label>

                  <Form.Control
                    type="number"
                    name="otp"
                    className={otpErrors.otp ? "error-field" : ""}
                    autoComplete="off"
                    {...registerOtp("otp")}
                  />
                  {otpErrors?.otp ? (
                    <p className="error-text">{otpErrors.otp.message}</p>
                  ) : (
                    ""
                  )}
                </Form.Group>

                <Button className="s-btn" type="submit">
                  {isLoading ? (
                    <Spinner animation="border" />
                  ) : timeLeft > 0 ? (
                    "Submit"
                  ) : (
                    "Re-sent OTP"
                  )}
                </Button>
              </Form>
            ) : (
            //   <Form onSubmit={handleOtpSubmit(reSentOTP)}>
                <Button className="s-btn" type="submit" onClick={reSentOTP}>
                  {isLoading ? (
                    <Spinner animation="border" />
                  ) : timeLeft > 0 ? (
                    "Submit"
                  ) : (
                    "Re-sent OTP"
                  )}
                </Button>
            //   </Form>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default VerifyOTP;
