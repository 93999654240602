import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import { checkEmail } from "../../validationSchema/authSchema";
import { sendVerificationEmail } from "../../utils/Api";
// import MailSentPopup from "../register/mailSentPopup";
import toast from "react-hot-toast";
import Layout from "../layout/layout";
import "./login.css";
import VerifyOTP from "../register/VerifyOTP";

const VerifyEmail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState();
  const [checkEmailInput, setCheckEmailInput] = useState("");
  const [show, setShow] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(checkEmail) });
  const handleModal = () => {
    setShow(true);
  };
  const handleChange = (e) => {
    const { value, name } = e.target;
    console.log({ value, name });
    setValue("email", value);
    setCheckEmailInput(value);
  };
  const handleVerifyEmail = async (values) => {
    setIsLoading(true);
    const res = await sendVerificationEmail(values);
    setIsLoading(false);
    if (res.status === 204) {
      handleModal();
      setTimeLeft(600);
    } else {
      toast.error(res.message);
    }
  };
  return (
    <Layout>
      <div className="forget-password-section">
        <div className="auth-page">
          <div className="forget-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="forget-form">
                    <h1>Your account is not verified.</h1>
                    <p className="auth-para">
                      Please enter your mail to get verify link.
                    </p>
                    <div className="forget-box">
                      <Form onSubmit={handleSubmit(handleVerifyEmail)}>
                        <Form.Group className="form-group">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            className={errors.email ? "error-field" : ""}
                            {...register("email")}
                            onChange={(e) => handleChange(e)}
                          />
                          <Button type="submit" className="send-btn">
                            {isLoading ? (
                              <Spinner animation="border" />
                            ) : (
                              "Send"
                            )}
                          </Button>
                          {errors?.email ? (
                            <p className="error-text">{errors.email.message}</p>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                      </Form>
                      <p className="already">
                        <Link to="/login" className="forget-link">
                          Back to Login
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        centered
        className="login-popup"
        show={show === "verifying"}
        onHide={() => handleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MailSentPopup
            handleModal={handleModal}
            text={"Verification link has been send to your mail"}
            checkModalType={false}
          />
        </Modal.Body>
      </Modal> */}

      <VerifyOTP
        setVerifyOtpPopup={setShow}
        verifyOtpPopup={show}
        setTimeLeft={setTimeLeft}
        timeLeft={timeLeft}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        checkEmailInput={checkEmailInput}
      />
    </Layout>
  );
};

export default VerifyEmail;
